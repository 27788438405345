<script>
import { getContext } from 'svelte';



  export let id;

  const popoverState = getContext("popoverState")
  let show = false;
  let calcXCenterLeft = 0;

  function relocateElementBySelector(element, destElement) {
    let elementParent = element.parentElement;
    elementParent.removeChild(element);
    destElement.appendChild(element);
  }

  function ensurePopoverOverlayWrapper() {
    const popoverOverlay = document.getElementById("popover-overlay-wrapper");
    if (!popoverOverlay) {
      let div = document.createElement("div");
      div.setAttribute("id", "popover-overlay-wrapper");
      document.body.appendChild(div);
      document.body.style.position = "relative";
    }
  }

  function initPopoverContent(el, id) {
    let wrapper = document.getElementById("popover-overlay-wrapper");
    let popoverContentSelector = "#popovercontent_" + id
    let popoverContent;
    let popoverContentInner;
    let initialPopoverContent = el.querySelector(".content");

    if (wrapper && initialPopoverContent) {
      let targetEl = el.querySelector("[slot=target]");
      let targetBound = targetEl.getBoundingClientRect();
      relocateElementBySelector(initialPopoverContent, wrapper);
      popoverContent = wrapper.querySelector(popoverContentSelector)
      popoverContent.style.display = "block";
      let contentBound = popoverContent.getBoundingClientRect();
      const topTopStyle =
        targetBound.y - popoverContent.clientHeight - targetEl.clientHeight / 2 + 10;
      const topBottomCenter =
        targetBound.x + targetBound.width / 2 - popoverContent.clientWidth / 2;

      popoverContent.style.left = String(topBottomCenter) + "px";
      popoverContent.style.top = String(topTopStyle) + "px";
      popoverContent.style.position = "absolute";
    }else{
      popoverContent = wrapper.querySelector(popoverContentSelector)
    }
    return popoverContent
  }

  $:{
    togglePopover($popoverState.el, $popoverState.show);
  }

  function togglePopover(el, show) {
    if (el === null) {return}
    const popoverEl = el.querySelector(".PopoverComponent")
    const id = popoverEl.id.split("_")[1]
    ensurePopoverOverlayWrapper();
    let popoverContent = initPopoverContent(popoverEl, id);
    show ? popoverContent.style.display = "block" : popoverContent.style.display = "none";
  }
</script>

<div class="PopoverComponent" id="popover_{id}">
  <div><slot name="target" /></div>

  <div id="popovercontent_{id}"  class="content" class:hiddenPopover={!show}>
    <slot name="content" />
  </div>
</div>

<style>
  .PopoverComponent {
    width: 100%;
    max-width: 100%;
  }
  :global(#popover-overlay-wrapper) {
    max-width: 100%;
  }
  .content.hiddenPopover {
    display: none;
  }
  .content{
    width: 400px;
    z-index: 100;
  }
  :global(.popoverContent) {
    color: var(--popover-text-color, #333);
    background-color: var(--popover-background-color, lightcyan);
    border: 2px solid var(--popover-border-color,lightcyan);
    padding: 5px;
  }
  :global(.popoverContent a) {
    color: var(--popover-link-color, blue);
  }
  .content::after{
    content: "▼";
    color: var(--popover-border-color,lightcyan);
    font-size: 34px;
    line-height: 14px;
    position: relative;
    left: calc(50% - 15px);

  }
</style>
