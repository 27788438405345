<script>
  import { getContext } from "svelte";

  export let primaryHeaders;

  let rowlabeltype = getContext("rowlabeltype")
  let categoryField = getContext("categoryField");
</script>

<div class="headerWrapper">
  <div class="headerPrimaryColumnLabel capitalize">{categoryField}</div>
  <div
    class="headerPrimaryColumns"
    style="grid-template-columns: repeat({primaryHeaders.length}, 1fr);"
  >
    {#each primaryHeaders as header}
      <div class="headerPrimaryColumn gcol-{header.id}"><span>{header.name}</span></div>
    {/each}
  </div>
</div>

<style lang="scss">
  .headerWrapper{
    display: flex;
    flex-wrap: nowrap;
    border-bottom: 8px solid var(--header-background-color);
  }
  .headerPrimaryColumns {
    display: grid;
    width: 100%;
    grid-auto-flow: row;
    grid-auto-flow: dense;
    grid-gap: 0 3px;
  }
  .headerPrimaryColumn {
    background-color: var(--header-background-color);
    color: var(--header-font-color);
    /* text-align: center; */
    padding: 0.6em 0;
    // border-left: 1px solid green;
    > span{
      margin-left: -0.56em;
    }
  }
  .headerPrimaryColumnLabel {
    width: 127px;
    flex-shrink: 0;
    padding: 0.6em;
    color: var(--header-font-color);
    border-right: 0 solid transparent;
    background-color: var(--header-background-color);
    margin-right: 3px;
  }

  .capitalize{
    text-transform:capitalize;
  }
</style>
