<script>
  import { getContext } from 'svelte';
  import { primaryScale } from '../stores';
  import { windowStartKm } from '../stores';
  import { closest } from '$lib/utils'

  export let gantttitle = "";
  let windowEndKm;
  $:{
    let windowEndKm = $windowStartKm + $primaryScale;
  }

  let scales = [5, 10, 15, 20, 30, 50]
  let maxScale = scales[scales.length -1]
  function scaleStep(e) {
    let closestValue = closest(e.target.value, scales)
    console.log(`closestValue: ${closestValue}`)
    e.target.value = closestValue;
    $primaryScale = closestValue;
  }

  const previous = getContext("previous");
  const next = getContext("next");
  const trackStartKm = getContext("trackStartKm");
  const trackEndKm = getContext("trackEndKm");
</script>

<div class="gantt-navi">

  <div class="chart-title"><h2>{gantttitle}</h2></div>
  <div class="start-km">Start km <input type="number" name="start-km" bind:value={$windowStartKm} /></div>
  <div class="chart-scale"><input type="range" name="chart-scale" id="chart-scale" list="chart-scales" step="1" min="5" value={$primaryScale} max="{maxScale}"
    on:input={scaleStep}> [{$primaryScale}]</div>
  <datalist id="chart-scales">
    <option value="5"></option>
    <option value="10"></option>
    <option value="15"></option>
    <option value="20"></option>
    <option value="30"></option>
    <option value="50"></option>
  </datalist>
  <button class="previous" on:click={previous} disabled={$windowStartKm <= trackStartKm}
    ><svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      class="bi bi-arrow-left-square-fill"
      viewBox="0 0 16 16"
    >
      <path
        d="M16 14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12zm-4.5-6.5H5.707l2.147-2.146a.5.5 0 1 0-.708-.708l-3 3a.5.5 0 0 0 0 .708l3 3a.5.5 0 0 0 .708-.708L5.707 8.5H11.5a.5.5 0 0 0 0-1z"
      />
    </svg></button
  >
  <!-- <button class="this" on:click={now}
    ><svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      class="bi bi-house-fill"
      viewBox="0 0 16 16"
    >
      <path
        fill-rule="evenodd"
        d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293l6-6zm5-.793V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"
      />
      <path
        fill-rule="evenodd"
        d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"
      />
    </svg></button
  > -->
  <button class="next" on:click={next} disabled={windowEndKm > trackEndKm}
    ><svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      class="bi bi-arrow-right-square-fill"
      viewBox="0 0 16 16"
    >
      <path
        d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1z"
      />
    </svg></button
  >
</div>

<style lang="scss">
  h2 {
    color: var(--header-background-color);
    text-align: center;
    font-size: 2.6em;
    font-weight: 100;
    padding: 0;
    margin: 0;
  }
  .gantt-navi {
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
    margin-top: 1em;
    > div{
      padding: 0 0.5em;
    }
  }
  .gantt-navi button {
    display: inline-block;
    background: transparent;
    border: 0 solid transparent;
    padding-right: 0;
    padding-bottom: 0;
  }
  .gantt-navi button:hover {
    box-shadow: none;
  }
  .chart-title{
    flex-grow: 1;
  }
  .start-km{
    > input {
      width: 4em;
    }
  }
  .bi {
    display: inline-block;
    position:relative;
    font-size: 2.6em;
    color: var(--header-background-color);
    top: -4px;
  }
</style>
