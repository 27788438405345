<script>
  import GanttHeaderPrimaryScale from "./GanttHeaderPrimaryScale.svelte";

  export let primaryHeaders;
</script>

<div class="GanttHeaderRow">
  <div class="GanttHeaderContent">
    <GanttHeaderPrimaryScale {primaryHeaders} />
  </div>
</div>

<style>
  .GanttHeaderRow {
    display: flex;
  }

  .GanttHeaderContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-grow: 1;
  }
</style>
