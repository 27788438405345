<!--<svelte:options tag="gantt-chart" /> -->
<svelte:options accessors={true} />

<script>
  import { getContext, setContext } from 'svelte';
  import { apiStoreGET } from "./api";
  import GanttNavi from "./lib/GanttNavi.svelte";
  import GanttTable from "./lib/GanttTable.svelte";
  import { primaryScale, windowEndKm, windowStartKm } from './stores';

  const objecturl = getContext("objecturl");
  const defaultquery = getContext("defaultquery");
  const rowlabeltype = getContext("rowlabeltype");
  const trackStartKm = getContext("trackStartKm");
  const trackEndKm = getContext("trackEndKm");

  let query = {
    sort_on: 'start',
    sort_order: 'ascending',
    criteria: [...defaultquery]
  }

  const query_attributes = [
    "UID",
    "Title",
  ]

  let searchurl = new URL(objecturl);
  console.log(searchurl.pathname)
  if(searchurl.pathname.endsWith("/view")) {
    searchurl.pathname = searchurl.pathname.replace("/view", "");
  }
  searchurl.pathname = searchurl.pathname + "/";
  let searchParams = new URLSearchParams(searchurl.search);
  // searchParams.append("metadata_fields", "facility_raw")
  // searchParams.append("metadata_fields", "start_m")
  // searchParams.append("metadata_fields", "end_m")
  // searchParams.append("metadata_fields", "Subject")
  // searchParams.append("metadata_fields", "UID")
  // searchParams.append("metadata_fields", "facility")
  // searchParams.set("name", "plone.app.vocabularies.Catalog")
  searchParams.set("query", JSON.stringify({"query": query["criteria"]}))
  // searchParams.set("attributes", JSON.stringify(query_attributes))
  // searchParams.set("fullobjects", "true")
  searchurl.search = searchParams.toString();

  let trackItemsResponse = apiStoreGET(searchurl.href);
  $windowStartKm = 0;

  //const colorMapping = [{"row_label":"732149b3b1584bcab13c38c3974f93ff","row_item_color_font":"#333333","row_item_color_bg":"#c061cb","row_color_font":"#333333","row_color_bg":"#000000"}]

  // XXX maybe facture this out into a registration of the addon:
  // Map content type to row label field name:
  const Ct2RowLabelMap = new Map([
    ["Facility", "facility_raw"],
    ["Location", "location"],
    ["Placeholder Plan Art", "plan_art"],
    ["Placeholder Plan Status", "plan_status"],
    ["Requirement Type", "requirement_type"],
    ["Tsi Type", "tsi_type"],
    ["Track", "track"],
    ["Facility Category", "facility_category"],
    ["IBN Stage", "ibn_stage"],
    ["Eba Area", "eba_subject_area"],
    ["Document Type", "document_type"],
    ["Applicable Tsi", "applicable_tsi"],
    ["Eiu", "eiu"],
    ["Eigv Classification", "eigv_classification"],
    ["Type Of Measure", "type_of_measure"],
    ["Protocol Type", "protocol_type"],
    ["Event Type", "dbec_event_type"],
    ["Procedure", "procedure"],
  ])

  const categoryField = Ct2RowLabelMap.get(rowlabeltype) || "track_view_row_label";
  console.log('categoryField')
  setContext("categoryField", categoryField)

  $: {
    $windowStartKm;
    $primaryScale;
    trackItemsResponse = apiStoreGET(searchurl.href);
  }

  function previous(e) {
    $windowStartKm = $windowStartKm - $primaryScale;
    if($windowStartKm < trackStartKm){ $windowStartKm = trackStartKm}
  }

  function next(e) {
    $windowStartKm = $windowStartKm + $primaryScale;
    if($windowStartKm > (trackEndKm - $primaryScale)){
      $windowStartKm = trackEndKm - $primaryScale;
    }
  }

  setContext("previous", previous)
  setContext("next", next)
</script>

  {#await $trackItemsResponse}
    loading ...
  {:then data}
    <div class="Gantt">
      <div class="Week">
        <GanttNavi
          />
        <GanttTable
          items={data.items}
          />
      </div>
    </div>
  {/await}

<style>
</style>
