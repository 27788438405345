import { writable, derived } from "svelte/store";

export const primaryScale = writable(10)

export const windowStartKm = writable(10)

export const windowEndKm = derived(
  [windowStartKm, primaryScale],
  ([$windowStartKm, $primaryScale]) => $windowStartKm + $primaryScale
)

export const windowStartM = derived(
  windowStartKm,
  ($windowStartKm) => $windowStartKm * 1000
)

export const windowEndM = derived(
  windowEndKm,
  ($windowEndKm) => $windowEndKm * 1000
)

export const primaryColumns = writable(Array(1))
export const secondaryColumns = writable(Array(1))
export const vocabMap = writable(new Map())
