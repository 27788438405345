import { derived, get, writable } from "svelte/store";
// import {extractVocab} from "./utils";

const cache = new Map();

export function apiStoreGET(url="", params = null) {
  const store = writable(new Promise(() => {}))
  let headers = new Headers();
  // headers.set("Content-type", "application/json");
  headers.set("Accept", "application/json");
  const body = params ? JSON.stringify(params) : undefined;
  const method = "GET";

  if(cache.has(url)){
    store.set(Promise.resolve(cache.get(url)));
  }

  const load = async () => {
    console.log(url)
    const response = await fetch(url, {method, body, headers});
    const data = await response.json();
    console.log(data)
    cache.set(url, data);
    store.set(Promise.resolve(data));
  }

  load();
  return store;
}

export function apiGET(url, params = null) {
  let headers = new Headers();
  headers.set("Content-type", "application/json");
  headers.set("Accept", "application/json");
  const body = params ? JSON.stringify(params) : undefined;
  const method = "GET";

  const load = async () => {
    const response = await fetch(url, {method, body, headers});
    const data = await response.json();
    console.log(data)
    return data;
  }
  return load();
}

export async function getVocabMap(vocaburl){
  const vocab = await apiGET(vocaburl);
  let vocabTitleMap = new Map();
  for (const item of await vocab.results) {
    vocabTitleMap.set(item.UID, item.Title);
  }
  return vocabTitleMap;
}

