import App from './App.svelte';

let targets = document.getElementsByTagName("track-chart");

for(let i = 0;i < targets.length; i++){
    let target = targets[i];
    let colorMappingStr = target.getAttribute('colormapping')
    let colorMapping = [];
    if(colorMappingStr){
      colorMappingStr = colorMappingStr.replaceAll("'", '"')
      colorMapping = JSON.parse(colorMappingStr);
    }
    const app_instance = new App({
    target: target,
    props: {
      currentobjecturl: target.getAttribute('currentobjecturl'),
      objecturl: target.getAttribute('objecturl'),
      navigationrooturl: target.getAttribute('navigationrooturl'),
      rowlabeltype: target.getAttribute('rowlabeltype'),
      defaultquery: target.getAttribute('query'),
      trackstartkm: parseInt(target.getAttribute('trackstartkm')),
      trackendkm: parseInt(target.getAttribute('trackendkm')),
      colormapping: colorMapping,
    }
  });
}
