<script>
  import { setContext } from 'svelte';
  import { writable } from 'svelte/store';
  import Popover from "./Popover.svelte";

  export let itemName;
  export let itemDescription;
  export let itemSpan;
  export let itemStart;
  export let startKm;
  export let endKm;
  export let overflowLeft = false;
  export let overflowRight = false;
  export let itemUrl;
  export let itemUid;
  let popoverState = writable({
    show: false,
    el: null,
  });

  setContext("popoverState", popoverState);

  function togglePopover() {
    $popoverState.show = !$popoverState.show;
    $popoverState.el = this;
  }

</script>

<div
  class="GanttItem"
  class:OverflowRight={overflowRight}
  class:OverflowLeft={overflowLeft}
  style="grid-column-start: {itemStart}; grid-column-end: span {itemSpan};"
  title="{itemName} (km: {startKm} - {endKm})"
  on:click={togglePopover}
>
  <div class="itemInnerWrapper">
    <Popover id={itemUid}>
      <div slot="target">
        <div class="summary">{itemName}</div>
      </div>
      <div slot="content">
        <div class="popoverContent">
          <p><i>km: {startKm} - {endKm}:</i></p>
          <p><b>{itemName}</b></p>
          <p>{itemDescription}</p>
          <a href={itemUrl}>Details</a>
        </div>
      </div>
    </Popover>
  </div>
</div>

<style lang="scss">
  .GanttItem {
    background-color: var(--row-item-color-bg);
    border-radius: 5px;
    color: var(--row-item-color-font);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    min-width: 5px;
    min-height: 1em;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .itemInnerWrapper {
    padding: 0.4em;
  }
  .summary {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .GanttItem > * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .OverflowRight {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .OverflowLeft {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
</style>
